import React from 'react';
import {Menu, MenuButton, MenuDivider, MenuItem, SubMenu} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/core.css";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/theme-dark.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import galaxyPng from "../../assets/gfx/galaxy.png";
import galaxyClusterPng from "../../assets/gfx/galaxy_cluster.png";
import quasarPng from "../../assets/gfx/quasar.png";
import nebulaPng from "../../assets/gfx/nebula.png";
import planetaryNebulaPng from "../../assets/gfx/planetary_nebula.png";
import superNovaRemnantPng from "../../assets/gfx/super_nova_remnant.png";
import darkNebulaPng from "../../assets/gfx/dark_nebula.png";
import openClusterAndNebulaPng from "../../assets/gfx/open_cluster_and_nebula.png";
import openClusterPng from "../../assets/gfx/open_cluster.png";
import globularClusterPng from "../../assets/gfx/globular_cluster.png";
import './assets/sitemenu.scss';
import {faCaretDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function MenuCharts() {
    return (
        <Menu transition menuButton={<MenuButton>Charts <FontAwesomeIcon icon={faCaretDown}/></MenuButton>}>
            <MenuItem href="/catalogues">Catalogues and Charts</MenuItem>
            {/*<MenuItem href="/search">Search</MenuItem>*/}

            <MenuDivider/>
            <MenuItem href="/planisphere">Planisphere</MenuItem>
            <MenuItem href="/almanac">Almanac</MenuItem>

            <MenuDivider/>
            <SubMenu label="Objects by Month">
                <MenuItem href="/monthlylist">Monthly DeepSkyList</MenuItem>
                <MenuItem href="/ra/6">January</MenuItem>
                <MenuItem href="/ra/8">February</MenuItem>
                <MenuItem href="/ra/10">March</MenuItem>
                <MenuItem href="/ra/12">April</MenuItem>
                <MenuItem href="/ra/14">May</MenuItem>
                <MenuItem href="/ra/16">June</MenuItem>
                <MenuItem href="/ra/18">July</MenuItem>
                <MenuItem href="/ra/20">August</MenuItem>
                <MenuItem href="/ra/22">September</MenuItem>
                <MenuItem href="/ra/0">October</MenuItem>
                <MenuItem href="/ra/2">November</MenuItem>
                <MenuItem href="/ra/4">December</MenuItem>
            </SubMenu>

            <MenuDivider/>
            <MenuItem href="/list-messier">Messier DeepSkyList</MenuItem>
            <MenuItem href="/list-caldwell">Caldwell DeepSkyList</MenuItem>
            <MenuItem href="/list-herschel">Herschel 400 DeepSkyList</MenuItem>
            <MenuItem href="/messiermarathon">Messier Marathon</MenuItem>

            <MenuDivider/>

            <MenuItem href="/objectsbyconstellation">Objects by constellation</MenuItem>
            <SubMenu label="Constellations">
                <SubMenu label="Andromeda - Bootes">
                    <MenuItem href="/constellation/And">Andromeda</MenuItem>
                    <MenuItem href="/constellation/Ant">Antlia</MenuItem>
                    <MenuItem href="/constellation/Aps">Apus</MenuItem>
                    <MenuItem href="/constellation/Aqr">Aquarius</MenuItem>
                    <MenuItem href="/constellation/Aql">Aquila</MenuItem>
                    <MenuItem href="/constellation/Ara">Ara</MenuItem>
                    <MenuItem href="/constellation/Ari">Aries</MenuItem>
                    <MenuItem href="/constellation/Aur">Auriga</MenuItem>
                    <MenuItem href="/constellation/Boo">Bootes</MenuItem>
                </SubMenu>
                <SubMenu label="Caelum - Circinus">
                    <MenuItem href="/constellation/Cae">Caelum</MenuItem>
                    <MenuItem href="/constellation/Cam">Camelopardalis</MenuItem>
                    <MenuItem href="/constellation/Cnc">Cancer</MenuItem>
                    <MenuItem href="/constellation/CVn">Canes Venatici</MenuItem>
                    <MenuItem href="/constellation/CMa">Canis Major</MenuItem>
                    <MenuItem href="/constellation/CMi">Canis Minor</MenuItem>
                    <MenuItem href="/constellation/Cap">Capricornus</MenuItem>
                    <MenuItem href="/constellation/Car">Carina</MenuItem>
                    <MenuItem href="/constellation/Cas">Cassiopeia</MenuItem>
                    <MenuItem href="/constellation/Cen">Centaurus</MenuItem>
                    <MenuItem href="/constellation/Cep">Cepheus</MenuItem>
                    <MenuItem href="/constellation/Cet">Cetus</MenuItem>
                    <MenuItem href="/constellation/Cha">Chamaeleon</MenuItem>
                    <MenuItem href="/constellation/Cir">Circinus</MenuItem>
                </SubMenu>
                <SubMenu label="Columba - Cygnus">
                    <MenuItem href="/constellation/Col">Columba</MenuItem>
                    <MenuItem href="/constellation/Com">Coma Berenices</MenuItem>
                    <MenuItem href="/constellation/CrA">Corona Australis</MenuItem>
                    <MenuItem href="/constellation/CrB">Corona Borealis</MenuItem>
                    <MenuItem href="/constellation/Crv">Corvus</MenuItem>
                    <MenuItem href="/constellation/Crt">Crater</MenuItem>
                    <MenuItem href="/constellation/Cru">Crux</MenuItem>
                    <MenuItem href="/constellation/Cyg">Cygnus</MenuItem>
                </SubMenu>
                <SubMenu label="Delphinus - Hydrus">
                    <MenuItem href="/constellation/Del">Delphinus</MenuItem>
                    <MenuItem href="/constellation/Dor">Dorado</MenuItem>
                    <MenuItem href="/constellation/Dra">Draco</MenuItem>
                    <MenuItem href="/constellation/Equ">Equuleus</MenuItem>
                    <MenuItem href="/constellation/Eri">Eridanus</MenuItem>
                    <MenuItem href="/constellation/For">Fornax</MenuItem>
                    <MenuItem href="/constellation/Gem">Gemini</MenuItem>
                    <MenuItem href="/constellation/Gru">Grus</MenuItem>
                    <MenuItem href="/constellation/Her">Hercules</MenuItem>
                    <MenuItem href="/constellation/Hor">Horologium</MenuItem>
                    <MenuItem href="/constellation/Hya">Hydra</MenuItem>
                    <MenuItem href="/constellation/Hyi">Hydrus</MenuItem>
                </SubMenu>
                <SubMenu label="Indus - Lyra">
                    <MenuItem href="/constellation/Ind">Indus</MenuItem>
                    <MenuItem href="/constellation/Lac">Lacerta</MenuItem>
                    <MenuItem href="/constellation/Leo">Leo</MenuItem>
                    <MenuItem href="/constellation/LMi">Leo Minor</MenuItem>
                    <MenuItem href="/constellation/Lep">Lepus</MenuItem>
                    <MenuItem href="/constellation/Lib">Libra</MenuItem>
                    <MenuItem href="/constellation/Lup">Lupus</MenuItem>
                    <MenuItem href="/constellation/Lyn">Lynx</MenuItem>
                    <MenuItem href="/constellation/Lyr">Lyra</MenuItem>
                </SubMenu>
                <SubMenu label="Mensa - Orion">
                    <MenuItem href="/constellation/Men">Mensa</MenuItem>
                    <MenuItem href="/constellation/Mic">Microscopium</MenuItem>
                    <MenuItem href="/constellation/Mon">Monoceros</MenuItem>
                    <MenuItem href="/constellation/Mus">Musca</MenuItem>
                    <MenuItem href="/constellation/Nor">Norma</MenuItem>
                    <MenuItem href="/constellation/Oct">Octans</MenuItem>
                    <MenuItem href="/constellation/Oph">Ophiuchus</MenuItem>
                    <MenuItem href="/constellation/Ori">Orion</MenuItem>
                </SubMenu>
                <SubMenu label="Pavo - Reticulum">
                    <MenuItem href="/constellation/Pav">Pavo</MenuItem>
                    <MenuItem href="/constellation/Peg">Pegasus</MenuItem>
                    <MenuItem href="/constellation/Per">Perseus</MenuItem>
                    <MenuItem href="/constellation/Phe">Phoenix</MenuItem>
                    <MenuItem href="/constellation/Pic">Pictor</MenuItem>
                    <MenuItem href="/constellation/Psc">Pisces</MenuItem>
                    <MenuItem href="/constellation/PsA">Piscis Austrinus</MenuItem>
                    <MenuItem href="/constellation/Pup">Puppis</MenuItem>
                    <MenuItem href="/constellation/Pyx">Pyxis</MenuItem>
                    <MenuItem href="/constellation/Ret">Reticulum</MenuItem>
                </SubMenu>
                <SubMenu label="Sagitta -Tucana">
                    <MenuItem href="/constellation/Sge">Sagitta</MenuItem>
                    <MenuItem href="/constellation/Sgr">Sagittarius</MenuItem>
                    <MenuItem href="/constellation/Sco">Scorpius</MenuItem>
                    <MenuItem href="/constellation/Scl">Sculptor</MenuItem>
                    <MenuItem href="/constellation/Sct">Scutum</MenuItem>
                    <MenuItem href="/constellation/Ser">Serpens</MenuItem>
                    <MenuItem href="/constellation/Sex">Sextans</MenuItem>
                    <MenuItem href="/constellation/Tau">Taurus</MenuItem>
                    <MenuItem href="/constellation/Tel">Telescopium</MenuItem>
                    <MenuItem href="/constellation/Tri">Triangulum</MenuItem>
                    <MenuItem href="/constellation/TrA">Triangulum Australe</MenuItem>
                    <MenuItem href="/constellation/Tuc">Tucana</MenuItem>
                </SubMenu>
                <SubMenu label="Ursa Major -Vulpecula">
                    <MenuItem href="/constellation/UMa">Ursa Major</MenuItem>
                    <MenuItem href="/constellation/UMi">Ursa Minor</MenuItem>
                    <MenuItem href="/constellation/Vel">Vela</MenuItem>
                    <MenuItem href="/constellation/Vir">Virgo</MenuItem>
                    <MenuItem href="/constellation/Vol">Volans</MenuItem>
                    <MenuItem href="/constellation/Vul">Vulpecula</MenuItem>
                </SubMenu>
            </SubMenu>

            <MenuDivider/>

            <MenuItem href="/objectsbytype">Objects by type</MenuItem>
            <SubMenu label="Objects by type">
                <MenuItem href="/type/gx"><img src={galaxyPng} alt="Img"/><img src={galaxyClusterPng} alt="Img"/><img src={quasarPng} alt="Img"/>Galaxies</MenuItem>
                <MenuItem href="/type/an"><img src={nebulaPng} alt="Img"/><img src={planetaryNebulaPng} alt="Img"/><img src={superNovaRemnantPng} alt="Img"/><img src={darkNebulaPng} alt="Img"/> Any Nebul&aelig;</MenuItem>
                <MenuItem href="/type/nb"><img src={nebulaPng} alt="Img"/><img src={openClusterAndNebulaPng} alt="Img"/><img src={darkNebulaPng} alt="Img"/> Nebul&aelig;</MenuItem>
                <MenuItem href="/type/pn"><img src={planetaryNebulaPng} alt="Img"/> Planetary Nebul&aelig;</MenuItem>
                <MenuItem href="/type/sc"><img src={openClusterAndNebulaPng} alt="Img"/><img src={openClusterPng} alt="Img"/><img src={globularClusterPng} alt="Img"/> Any Star Cluster</MenuItem>
                <MenuItem href="/type/oc"><img src={openClusterPng} alt="Img"/> Open Clusters</MenuItem>
                <MenuItem href="/type/gc"><img src={globularClusterPng} alt="Img"/> Globular Clusters</MenuItem>
            </SubMenu>

            <MenuItem href="/planetarynebulae">Planetary Nebulae</MenuItem>
            <MenuItem href="/deepskyobjectsnearstars">Objects near brightish stars</MenuItem>

            <MenuDivider/>
            <MenuItem href="/chart/1.4398966328953218/0/2000/800/1600">Interactive Star Chart</MenuItem>

            <MenuDivider/>
            <MenuItem href="/editableallskychart">Editable Chart</MenuItem>

            <SubMenu label="Tirion Charts">
                <MenuItem href="/tirioncharts">Tirion Charts Overall Map</MenuItem>
                <MenuItem href="/tirion/1">Tirion Chart 1</MenuItem>
                <MenuItem href="/tirion/2">Tirion Chart 2</MenuItem>
                <MenuItem href="/tirion/3">Tirion Chart 3</MenuItem>
                <MenuItem href="/tirion/4">Tirion Chart 4</MenuItem>
                <MenuItem href="/tirion/5">Tirion Chart 5</MenuItem>
                <MenuItem href="/tirion/6">Tirion Chart 6</MenuItem>
                <MenuItem href="/tirion/7">Tirion Chart 7</MenuItem>
                <MenuItem href="/tirion/8">Tirion Chart 8</MenuItem>
                <MenuItem href="/tirion/9">Tirion Chart 9</MenuItem>
                <MenuItem href="/tirion/10">Tirion Chart 10</MenuItem>
                <MenuItem href="/tirion/11">Tirion Chart 11</MenuItem>
                <MenuItem href="/tirion/12">Tirion Chart 12</MenuItem>
                <MenuItem href="/tirion/13">Tirion Chart 13</MenuItem>
                <MenuItem href="/tirion/14">Tirion Chart 14</MenuItem>
                <MenuItem href="/tirion/15">Tirion Chart 15</MenuItem>
                <MenuItem href="/tirion/16">Tirion Chart 16</MenuItem>
                <MenuItem href="/tirion/17">Tirion Chart 17</MenuItem>
                <MenuItem href="/tirion/18">Tirion Chart 18</MenuItem>
                <MenuItem href="/tirion/19">Tirion Chart 19</MenuItem>
                <MenuItem href="/tirion/20">Tirion Chart 20</MenuItem>
                <MenuItem href="/tirion/21">Tirion Chart 21</MenuItem>
                <MenuItem href="/tirion/22">Tirion Chart 22</MenuItem>
                <MenuItem href="/tirion/23">Tirion Chart 23</MenuItem>
                <MenuItem href="/tirion/24">Tirion Chart 24</MenuItem>
                <MenuItem href="/tirion/25">Tirion Chart 25</MenuItem>
                <MenuItem href="/tirion/26">Tirion Chart 26</MenuItem>
            </SubMenu>

            <MenuItem href="/synscanalignmentstars">Synscan Alignment Stars</MenuItem>
        </Menu>
    )
}

