import React from 'react';
import {Menu, MenuButton, MenuItem} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/core.css";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/theme-dark.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import './assets/sitemenu.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";

export default function MenuUser() {
    return (
        <Menu transition menuButton={<MenuButton><FontAwesomeIcon icon={faUser}/></MenuButton>}>
            <MenuItem href="/dashboard">Dashboard</MenuItem>
            {/*<MenuItem href="/mosiacmessier">Messier Mosiac</MenuItem>*/}
            {/*<MenuItem href="/mosaiccaldwell">Caldwell Mosiac</MenuItem>*/}
            {/*<MenuItem href="/mosaicherschel">Herschel Mosiac</MenuItem>*/}
            {/*<MenuItem href="/equipmentsummary">Equipment Summary</MenuItem>*/}
            {/*<MenuItem href="/observingsummary">Observing Summary</MenuItem>*/}
            {/*<MenuItem href="/imagingsummary">Imaging Summary</MenuItem>*/}
            {/*/temperature*/}
            {/*/lunar*/}
            {/*/solaractivity*/}
            {/*/constellation/:abbr*/}
        </Menu>
    )
}

